import { invokeApi } from "../../utils";

export const QRCodeImg = async (data) => {
  const requestObj = {
    path: `/api/user/qrcode_list`,
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
   
};

  return invokeApi(requestObj);
};