import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ShowBalance } from "../DAL/Balance/Balance";
import { enqueueSnackbar } from "notistack";
import { AddDeposit } from "../DAL/Deposit/Deposit";
import Footer from "./Footer";
import { FormHelperText } from "@mui/material";

const Recharge = ({ value }) => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    amount: "",
  });
  const [error, setError] = useState([]);
  const [balance, setBalance] = useState(0);

  const handleAmountClick = (value) => {
    setAmount(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmited = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("amount", amount);

    const resp = await AddDeposit(formData);
    if (resp?.status === true) {
      localStorage.setItem("user_data", JSON.stringify(resp?.data));
      enqueueSnackbar(resp?.message, { variant: "success" });
      // navigate(`/payment-method/${value?.transaction_id}`);
      navigate("/payment-method", { state: resp?.data });
    } else {
      setError(resp?.message);
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
    }
  };

  const getBalance = async () => {
    const resp = await ShowBalance();
    if (resp.status === true) {
      setBalance(resp.data.balance);
    }
  };

  useEffect(() => {
    getBalance();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div
        className="text-center text-white"
        style={{ backgroundColor: "#1C1C29", height: "100vh" }}
      >
        <div style={{ backgroundColor: "#3A4E68" }}>
          <div className="text-start container">
            <FaArrowLeft className="text-white" onClick={() => navigate(-1)} />
          </div>
          <h1 className="py-3">Recharge</h1>
        </div>
        <div className="container">
          <div style={{ backgroundColor: "#343A40", padding: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>{balance}</h3>
            <h5 style={{ fontStyle: "italic" }}>Current Balance</h5>
          </div>
        </div>
        <div className="container">
          <h6 className="mt-3">Recharge Amount ($)</h6>
          <input
            type="number"
            placeholder="Please select the amount *"
            value={amount}
            onChange={handleChange}
            required
            style={{
              border: "none",
              borderBottom: "3px solid grey",
              borderRadius: "10px",
              backgroundColor: "transparent",
              width: "90%",
              color: "white",
            }}
          />
          <FormHelperText
            className="text-danger mx-5"
            sx={{ fontSize: "13px" }}
          >
            {error?.amount && error?.amount[0]}
          </FormHelperText>
        </div>
        <div className="container mt-5">
          <div className="row">
            {[10, 30, 50, 100, 500, 1000, 5000, 10000].map((value) => (
              <div className="col-3" key={value}>
                <h6
                  style={{
                    border: "1px solid white",
                    borderRadius: "30px",
                    padding: "5px 0px",
                    fontStyle: "italic",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAmountClick(value)}
                >
                  ${value}
                </h6>
              </div>
            ))}
          </div>
        </div>
        <div className="container">
          <button
            className="py-2 my-3 fw-bold"
            style={{
              width: "90%",
              borderRadius: "5px",
              backgroundColor: "#EA5B69",
              border: "none",
              color: "white",
            }}
            onClick={handleSubmited} // Button now calls handleSubmited
          >
            Confirm
          </button>
          <ol className="text-start">
            <li>
              Payment amount must be the same as order amount, otherwise it will
              not be automatically credited to the account.
            </li>
            <li>
              If Deposit and Withdraw are not received, please consult your
              superior for other questions.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Recharge;
