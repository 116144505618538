import React, { useEffect } from 'react'
import logo from '../assets/Images/logo.png'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    if(!localStorage.getItem("token")){
      navigate('/')
    }
  },[])
  return (
    <>
      <div style={{backgroundColor:"#1D1D2A",height:"100vh"}}>
      <div className='text-center' style={{backgroundColor:"#3D526B"}}>
      <h1 className='text-white py-2'>Customer Support</h1>
      </div>
     <div className="container">
     <div style={{backgroundColor:"#212529",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
      <img src={logo} alt="" width={100} />
        <p className='text-white'>Telegram Contact <br /> WKH: 00;00;00; 23:59:00</p> 
      <a href='https://t.me/sportstrading12' className='btn btn-danger' target='_blank' rel='noopener noreferrer'>Contact</a>
      </div>
      <div style={{backgroundColor:"#212529",display:"flex",justifyContent:"space-around",alignItems:"center",marginTop:"20px"}}>
      <img src={logo} alt="" width={100}/>
      <p className='text-white'>Telegram Contact <br /> WKH: 00;00;00; 23:59:00</p> 
      <a href='https://t.me/sportstrading12' className='btn btn-danger' target='_blank' rel='noopener noreferrer'>Contact</a>
      </div>
     </div>
     <Footer/>
      </div>
    </>
  )
}

export default Services
