import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { QRCodeImg } from "../DAL/QR/QR";
import { ImageUrl } from "../config/config";
import { useSnackbar } from "notistack";
import Footer from "./Footer";

const PaymentMethod = ({ keyContent, value }) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [qrImage, setQrImage] = useState("");
  const [key, setKey] = useState();
  const [ids, setIds] = useState();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        enqueueSnackbar("Code copy successfully", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
  };

  const getCodeImg = async () => {
    const resp = await QRCodeImg();
    if (resp.status === true) {
      setQrImage(resp.data.qrcode);
      setIds(resp.data.transaction_id);
      setKey(resp.data.key);
    } else {
      console.log("not found anything");
    }
  };

  useEffect(() => {
    getCodeImg();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div
        className="text-center text-white"
        style={{
          backgroundColor: "#1C1C29",
          paddingBottom: "100px",
          height: "100vh",
        }}
      >
        <div style={{ backgroundColor: "#3A4E68" }}>
          <div className="text-start container">
            <Link to={"/recharge"}>
              <FaArrowLeft className="text-white " />
            </Link>
          </div>
          <h1 className="py-3">Payment Method</h1>
        </div>
        <div className="container text-white">
          <h1>{id}</h1>
          <h3 className="font-italic">
            PLEASE PAY USDT USING TRC20 NETWORK
          </h3>{" "}
          <br />
          <img src={ImageUrl + qrImage} alt="" width={200} /> <br />
          <span className="fw-bold">Transaction Id :</span>
          <span className="mx-2">{state?.transaction_id}</span>
          <h1 className="text-white">{id}</h1>
          <div
            className="my-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <h4>Wallet Address:</h4>
            <h4 onClick={handleCopy} style={{ cursor: "pointer" }}>
              {key}
            </h4>
          </div>
          <ol className="text-start">
            <li>
              Payment amount must be the same as order amount otherwise it will
              not be automatically credited to the account.
            </li>
            <li>
              if Deposit and Withdraw is not recived so please consult your
              superior for other Questions.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentMethod;
