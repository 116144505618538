import React from 'react'
import coming from '../assets/Images/coming.png'
import Footer from './Footer'

const Coming = () => {
  
  return (
    <>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
      <img src={coming} alt="" style={{width:"50%",margin:"auto"}} />
    </div>
    <Footer/>
    </>
  )
}

export default Coming
