import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaFolderPlus } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row text-center text-secondary my-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <AiFillHome
                className="icon "
                style={{ color: "silver", cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
              <p
                style={{ fontStyle: "italic", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                Home
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/assets"}>
                <FaFolderPlus
                  className="icon"
                  style={{ color: "silver", cursor: "pointer" }}
                  onClick={() => navigate("/assets")}
                />
              </Link>
              <p
                style={{ fontStyle: "italic", cursor: "pointer" }}
                onClick={() => navigate("/assets")}
              >
                Assets
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/services"}>
                <FaEnvelope
                  className="icon"
                  style={{ color: "silver", cursor: "pointer" }}
                  onClick={() => navigate("/services")}
                />
              </Link>
              <p
                style={{ fontStyle: "italic", cursor: "pointer" }}
                onClick={() => navigate("/services")}
              >
                Services
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/profile"}>
                <IoMdPerson
                  className="icon"
                  style={{ color: "silver", cursor: "pointer" }}
                  onClick={() => navigate("/profile")}
                />
              </Link>
              <p
                style={{ fontStyle: "italic", cursor: "pointer" }}
                onClick={() => navigate("/profile")}
              >
                Profile
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
