import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  boxShadow: 28,
  textAlign: "center",
  color: "white",
  p: 4,
};

export default function RefferalModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState("");

  const handleCopy = () => {
    navigator.clipboard
      .writeText("https://www.sportstrading.online/register?code="+data?.referral_code)
      .then(() => {
        enqueueSnackbar("Code copy successfully", { variant: "success" });
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    setData(userData);
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <h5 className="fw-bold mt-1">Code:</h5>
            <Typography variant="h6">{data?.referral_code}</Typography>
          </div>
          <Button
            variant="contained"
            className="mt-1"
            color="success"
            onClick={handleCopy}
          >
            Copy Code
          </Button>
          <h6>If someone registers an account using this referral code, you will receive 5% of their first deposit.</h6>
        </Box>
      </Modal>
    </div>
  );
}
