import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Typography } from "@mui/material";
import { RecordWithdraw } from "../DAL/WithdrawRecord/WithdrawRecord";
import Footer from "./Footer";

export default function WithdrawRecord() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const handleData = async () => {
    try {
      const resp = await RecordWithdraw();
      if (resp?.data) {
        setRows(resp.data);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  useEffect(() => {
    handleData();
      if(!localStorage.getItem("token")){
        navigate('/')
      }
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#3A4E68" }}>
        <div className=" container ">
          <Link to={"/profile"}>
            <FaArrowLeft className="text-white" />
          </Link>
        </div>
        <h1 className="py-3 text-center text-white">Withdraw Record</h1>
      </div>
      <div className="container" style={{ marginBottom: "100px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.transaction_id} // Use a unique key
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.amount}
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{
                        color:
                          row.status === "0" ? "error.main" : "success.main",
                      }}
                    >
                      {row.status === "0" ? "pending" : "approve"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Footer/>
    </>
  );
}
