/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import sa from "../assets/Images/sa.jpg";
import sa1 from "../assets/Images/sa1.jpg";
import sa2 from "../assets/Images/sa2.jpg";
import sa3 from "../assets/Images/sa3.png";
import sa4 from "../assets/Images/sa4.webp";
import sa5 from "../assets/Images/sa5.webp";
import sa6 from "../assets/Images/sa6.webp";
import sa7 from "../assets/Images/sa7.jpg";
import sa8 from "../assets/Images/sa8.jpg";
import { useNavigate } from "react-router-dom";

const Showing = ({ val }) => {
  const navigate = useNavigate();
  const data = [
    {
      id: 4,
      image: sa8,
      text: "SA VS IRE",
    },
    {
      id: 5,
      image: sa1,
      text: "IND VS New Zealand",
    },
    {
      id: 6,
      image: sa2,
      text: "SA VS USA",
    },
    {
      id: 7,
      image: sa3,
      text: "USA VS SA",
    },
    {
      id: 8,
      image: sa4,
      text: "USA VS SA",
    },
    {
      id: 9,
      image: sa5,
      text: "SA VS USA",
    },
    {
      id: 10,
      image: sa6,
      text: "USA VS SA",
    },
    {
      id: 11,
      image: sa7,
      text: "USA VS SA",
    },
  ];
  const handleNav = (val) => {
    navigate(`/card-details/${val?.id}`, {
      state: {
        image: val?.image, // Pass the image URL or file here
      },
    });
  };
  return (
    <div>
      <div className="container mt-3">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="logo-text fw-bold">Showing Up</h3>
          <p className="logo-text fw-bold">240 Departments </p>
        </div>
        <div className="row mt-4 mb-3">
          {data?.map((val) => (
            <>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-3">
                <div
                  class="card"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    className="card-img-top"
                    src={val?.image}
                    alt="Card image cap"
                    onClick={() => handleNav(val)}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-white logo-text fw-bold fst-italic">
                      {val?.text}
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Showing;
