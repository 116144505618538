import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { TicketPuchase } from "../DAL/Ticket/Ticket";
import { useSnackbar } from "notistack";
import Footer from "./Footer";

const CardsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [addcount, setAddcount] = useState(1);
  const [amount, setAmount] = useState(3);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const location = useLocation();
  const { image } = location.state || {};
  const getPercentage = () => {
    if (amount <= 10) {
      return "3.50";
    } else if (amount >= 10 && amount <= 20) {
      return "3.80";
    } else if (amount > 20 && amount <= 50) {
      return "3.90";
    } else if (amount > 50 && amount <= 100) {
      return "4";
    } else if (amount > 100 && amount < 200) {
      return "4";
    } else if (amount > 200 && amount < 500) {
      return "4.10";
    } else if (amount > 500 && amount < 1000) {
      return "4.20";
    } else if (amount > 1000 && amount < 5000) {
      return "4.30";
    } else {
      return "4.50";
    }
  };

  const increment = () => {
    setAddcount(addcount + 1);
  };

  const decrement = () => {
    if (addcount > 1) {
      setAddcount(addcount - 1);
    }
  };

  const calculatePercentageValue = () => {
    const amountValue = parseFloat(amount);
    const percentageValue = parseFloat(getPercentage());

    if (isNaN(amountValue) || isNaN(percentageValue)) {
      return "Invalid amount or percentage"; // Fallback if conversion fails
    }

    return ((amountValue * percentageValue) / 100).toFixed(3);
  };

  const handleAddAmount = (e) => {
    const value = e.target.value < 0 ? 1 : e.target.value;
    setAddcount(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("amount", amount.toFixed(2));
    formData.append("percentage", getPercentage());
    formData.append("profit", calculatePercentageValue());
    formData.append("ticket_id", id);
    const resp = await TicketPuchase(formData);
    if (resp.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };
  useEffect(() => {
    setAmount(addcount * 3);
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [addcount]);
  return (
    <>
      <div style={{ height: "100vh", backgroundColor: "#1D1D2A" }}>
        <div className="container text-white">
          <h3 style={{ fontStyle: "italic" }}>Release Time:</h3>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button className="btn btn-secondary text-warning">
              Price: $10/Ticket
            </button>
            <button className="btn btn-secondary text-warning">
              Voucher: 3sheet/Ticket
            </button>
          </div>
          <h2 style={{ fontStyle: "italic" }}>Introduction:</h2>
          <h6 style={{ fontStyle: "italic" }}>Actors:</h6>
          <button
            type="button"
            className="mt-5"
            onClick={handleShow}
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "50px",
              backgroundColor: "#EA5B69",
              border: "none",
              color: "white",
            }}
          >
            But it Now
          </button>
        </div>
        {showModal && (
          <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div
                className="modal-content text-white"
                style={{ backgroundColor: "#2B2B37" }}
              >
                <div className="modal-header">
                  {image && (
                    <img
                      src={image}
                      alt="Card"
                      style={{ width: "100px", height: "auto" }}
                    />
                  )}
                  <h5 className="modal-title">Release Time:</h5>
                </div>
                <div className="modal-body">
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <button className="btn btn-secondary text-warning">
                      Price: $10/Ticket
                    </button>
                    <button className="btn btn-secondary text-warning">
                      Voucher: 3sheet/Ticket
                    </button>
                  </div>
                  <div className="container">
                    <div
                      className="row mt-2 mb-2"
                      style={{ backgroundColor: "#343A40" }}
                    >
                      <div className="col-6">
                        <h5>Voucher</h5>
                        <h5>Services Charge:</h5>
                      </div>
                      <div className="col-6">
                        <h5>3 Sheets</h5>
                        <h5>20%</h5>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h5>Purchase Quanity</h5>
                      </div>
                      <div className="col-6 ">
                        <FaPlus onClick={increment} />
                        <input
                          type="number"
                          onChange={handleAddAmount}
                          value={addcount}
                          className="mx-2 text-center text-white"
                          style={{
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            width: "100px",
                          }}
                        />
                        <FaMinus onClick={decrement} />
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-4">
                        <h6>Amount X </h6>
                      </div>
                      <div className="col-4">
                        <h6>Gain = </h6>
                      </div>
                      <div className="col-4">
                        <h6>Estimated Profit</h6>
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#343A40" }}>
                      <div className="col-4">
                        <h6>{amount.toFixed(2)}</h6>
                      </div>
                      <div className="col-4">
                        <h6>{getPercentage()}</h6>
                      </div>
                      <div className="col-4">
                        <h6>{calculatePercentageValue()}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-6 text-center">Payment Type</div>
                      <div className="col-6">
                        <label>Balance </label> <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-lg  btn-secondary"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg text-white"
                    onClick={handleSubmit}
                    style={{ backgroundColor: "#E85562" }}
                  >
                    Confirm Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CardsDetails;
