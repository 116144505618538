import { invokeApi } from "../../utils";

export const AddDeposit = async (data) => {
  const requestObj = {
    path: `/api/add_deposit`,
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    postData: data,
  };
  return invokeApi(requestObj);
};
