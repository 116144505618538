// import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
// import { ShowAssets } from '../DAL/Asset/Assets';

// const Assets = () => {
//   const [userData, setUserData]  = useState({
//     investment: '0.00',
//     totalProfit: '0.00',
//     totalBalance: '0.00',
//     totalTicket: 0,
//   });
//   console.log(setUserData,"jdsfskjf")

//   const UserInfo=async()=>{
//     const resp = await ShowAssets();
//     console.log(resp,"hjsdjks")
//     if(resp.message===true){
//       setUserData({
//         investment: data.investment,
//                     totalProfit: data.totalprofit,
//                     totalBalance: data.totalbalance,
//                     totalTicket: data.totalticket,
//       });
     
//     }
//   }
//   useEffect(()=>{
//     UserInfo();
//   })
  
//   return (
//     <>
//       <div className=' text-center text-white' style={{backgroundColor:"#1D1D2A",paddingBottom:"100px"}}>
//       <div style={{backgroundColor:"#3A4E68"}}>
//         <h1 className='py-3'>My Assets</h1>
//       </div>
//       <div className='assets-image'>
//         <h1>{userData.totalBalance}</h1>
//         <h1>My Total Assets</h1>
//       </div>
//       <div className="container">
//         <div className="row mt-4">
//             <div className="col-lg-6 col-sm-6 col-md-6 col-6 text-start">
//                <p style={{borderLeft:"10px solid #D9404F",borderRadius:"3px"}}>&nbsp; Available Asset: {userData.totalProfit}</p>
//                <p style={{borderLeft:"10px solid #007BFF",borderRadius:"3px"}}>&nbsp; Locked Asset: {userData.investment}</p>
//             </div>
//             <div className="col-lg-6 col-sm-6 col-md-6 col-6 text-end">
//                 <p>Ticket Quota : {userData.totalTicket}</p>
//                 <p>Freeze Assets : 0</p>
//             </div>
//         </div>
//       </div>
//         <hr />
//         <div className='container py-2'>
//         <div className="row">
//             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
//            <Link to={"/recharge"}> <button className='btn1'>Recahrge</button></Link>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
//             <Link to={"/withdraw"}><button className='btn1'>Withdraw</button></Link>
//             </div>
//         </div>
//         </div>
//         <hr />
//         <div className="container mb-5">
//         <div className="container">
//             <div className="row p-3"style={{backgroundColor:"#343A40",borderRadius:"10px"}}>
//                 <div className="col-lg-6  col-6 text-start">
//                     <h4 className='text-danger ' style={{fontStyle:"italic"}}>$ 0.07 </h4>
//                     <h6 style={{fontSize:"15px",fontStyle:"italic"}}>Today's Earning</h6>
//                 </div>
//                 <div className="col-lg-6   col-6 text-start" style={{borderLeft:"1px solid white"}}>
//                     <p style={{fontStyle:"italic"}}>Bought Today: 1</p>
//                     <p style={{fontSize:"11px",fontStyle:"italic"}}>Guaranteed amount:0.06$</p>  
//                 </div>
//             </div>
//         </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Assets

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from './Footer';

const Assets = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    investment: '0.00',
    totalProfit: '0.00',
    totalBalance: '0.00',
    totalTicket: 0,
  });

  useEffect(() => {
    const token = localStorage.getItem('token'); 
    
    if (token) {
      axios.get('https://sportstrading.online/backend/api/user/assets', {
        headers: {
          Authorization: `Bearer ${token}`, 
        }
      })
      .then((response) => {

        const data = response.data;
        if (data.status === true) {
          setUserInfo({
            investment: data.investment,
            totalProfit: data.totalprofit,
            totalBalance: data.totalbalance,
            totalTicket: data.totalticket,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    } else {
      console.error("No token found");
    }

    if(!token){
      navigate('/')
    }
  }, []);

  return (
    <>
      <div className='text-center text-white' style={{backgroundColor:"#1D1D2A", paddingBottom:"100px"}}>
        <div style={{backgroundColor:"#3A4E68"}}>
          <h1 className='py-3'>My Assets</h1>
        </div>
        <div className='assets-image'>
          <h1>0</h1> {/* Total balance from the API */}
          <h1>My Total Assets</h1>
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-6 col-sm-6 col-md-6 col-6 text-start">
              <p style={{borderLeft:"10px solid #D9404F", borderRadius:"3px"}}>
                &nbsp; Available Asset: {userInfo.totalBalance} 
              </p>
              <p style={{borderLeft:"10px solid #007BFF", borderRadius:"3px"}}>
                &nbsp; Locked Asset: {userInfo.investment}
              </p>
            </div>
            <div className="col-lg-6 col-sm-6 col-md-6 col-6 text-end">
              <p>Ticket Quota: 0 </p>
              <p>Freeze Assets: 0</p>
            </div>
          </div>
        </div>
        <hr />
        <div className='container py-2'>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <Link to={"/recharge"}>
                <button className='btn1'>Recharge</button>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <Link to={"/withdraw"}>
                <button className='btn1'>Withdraw</button>
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div className="container mb-5">
          <div className="container">
            <div className="row p-3" style={{backgroundColor:"#343A40", borderRadius:"10px"}}>
              <div className="col-lg-6 col-6 text-start">
                <h4 className='text-danger' style={{fontStyle:"italic"}}>
                  $ {userInfo.totalProfit} 
                </h4>
                <h6 style={{fontSize:"15px", fontStyle:"italic"}}>Today's Earning</h6>
              </div>
              <div className="col-lg-6 col-6 text-start" style={{borderLeft:"1px solid white"}}>
                <p style={{fontStyle:"italic"}}>Bought Today: {userInfo.totalTicket}</p>
                <p style={{fontSize:"11px", fontStyle:"italic"}}>Guaranteed amount: 0.00$</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Assets;
