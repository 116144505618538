import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import one from "../assets/Images/1.jpg";
import two from "../assets/Images/2.jpg";
import three from "../assets/Images/3.jpg";
import four from "../assets/Images/4.jpg";
import five from "../assets/Images/5.jpg";

export default function CarousalSlider() {
  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
  };
  return (
    <Slider {...settings}>
      <div className="sliderImage">
        <img
          src={one}
          width={"100%"}
          height={530}
          style={{ borderRadius: "20px" }}
          alt=""
        />
      </div>
      <div className="sliderImage">
        <img
          src={two}
          width={"100%"}
          height={530}
          style={{ borderRadius: "20px" }}
          alt=""
        />
      </div>
      <div className="sliderImage">
        <img
          src={three}
          width={"100%"}
          height={530}
          style={{ borderRadius: "20px" }}
          alt=""
        />
      </div>
      <div className="sliderImage">
        <img
          src={four}
          width={"100%"}
          height={530}
          style={{ borderRadius: "20px" }}
          alt=""
        />
      </div>
      <div className="sliderImage">
        <img
          src={five}
          width={"100%"}
          height={530}
          style={{ borderRadius: "20px" }}
          alt=""
        />
      </div>
    </Slider>
  );
}
