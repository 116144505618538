import { invokeApi } from "../../utils";

export const UserRegister = async (data) => {
  const requestObj = {
    path: `/api/register`,
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UserLogin = async (data) => {
  const requestObj = {
    path: `/api/login`,
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const UserLogout = async () => {
  const requestObj = {
    path: `/api/logout`,
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return invokeApi(requestObj);
};
