import React, { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { ShowMember } from '../DAL/Member/Member';
import Footer from './Footer';

const Membership = () => {
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);

    const handleMember = async ()=>{
        const resp = await ShowMember();
        if(resp.status===true){
            setMembers(resp);
        }else{
            console.log("notworking")
        }
    }
    useEffect(()=>{
        handleMember();
          if(!localStorage.getItem("token")){
            navigate('/')
          }
    },[])

    

  return (
    < >
      <div className='text-center text-white' style={{height:"100vh"}}>
      <div style={{ backgroundColor: "#3A4E68" }}>
        <div className=" container ">
            <FaArrowLeft className="text-white" onClick={()=>navigate(-1)}/>
        </div>
        <h1 className="py-3 text-center text-white">Members Record</h1>
      </div>
      <div className="container">
        <div className="row">
            <div className="col-6">
            <div style={{width:"80%",margin:"auto",height:"50vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
      <h1>Total Members</h1>
      <h1>Total Profit</h1>
      </div>
            </div>
            <div className="col-6">
            <div style={{width:"80%",margin:"auto",height:"50vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
      <h1>{members?.member}</h1>
      <h1>{members?.member_profit}</h1>
      </div>
            </div>
        </div>
      </div>
      
      </div>
      <Footer/>
    </>
  )
}

export default Membership
