import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { AddWithdraw } from "../DAL/Withdraw/Withdraw";
import { useSnackbar } from "notistack";
import { ShowBalance } from "../DAL/Balance/Balance";
import Footer from "./Footer";
import { FormHelperText } from "@mui/material";

const Withdraw = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [balance, setBalance] = useState();
  const [amount, setAmount] = useState({
    amount: "",
    usdt_address: "",
    wallet_type: "",
  });
  const [error, setError] = useState([]);
  const handleValue = (e) => {
    const name = e.target.name;
    const value = e.target.value < 0 ? 1 : e.target.value;
    console.log(value, "dsjkfdhksfjkld");
    setAmount((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("amount", amount?.amount);
    formData.append("wallet_type", "TRC20");
    formData.append("usdt_address", amount?.usdt_address);

    const resp = await AddWithdraw(formData);
    if (resp.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/");
      setAmount({});
    } else {
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
      setAmount({});
    }
    return;
  };
  const getBalance = async () => {
    const resp = await ShowBalance();
    if (resp.status === true) {
      setBalance(resp.data.balance);
    }
  };
  useEffect(() => {
    getBalance();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div
        className="text-center text-white"
        style={{ backgroundColor: "#1C1C29", paddingBottom: "100px" }}
      >
        <div style={{ backgroundColor: "#3A4E68" }}>
          <div className="text-start container">
            {/* <Link to={"/assets"}> */}
            <FaArrowLeft className="text-white " onClick={() => navigate(-1)} />
            {/* </Link> */}
          </div>
          <h1 className="py-3">Withdraw</h1>
        </div>
        <div className="container ">
          <div style={{ backgroundColor: "#343A40", padding: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>{balance}</h3>
            <h5 style={{ fontStyle: "italic" }}>Current Balance</h5>
          </div>
        </div>
        <div className="container ">
          <h6 className="mt-3">Enter Amount *</h6>
          <input
            type="number"
            name="amount"
            onChange={handleValue}
            required
            value={amount?.amount}
            style={{
              border: "none",
              borderBottom: "3px solid grey",
              borderRadius: "10px",
              backgroundColor: "transparent",
              width: "90%",
              margin: "auto",
              color: "white",
            }}
          />
          <FormHelperText className="text-danger" sx={{ fontSize: "13px" }}>
            {error?.amount && error?.amount[0]}
          </FormHelperText>
          <br />
          <h6 className="mt-3">USDT Address *</h6>
          <input
            type="name"
            name="usdt_address"
            onChange={handleValue}
            required
            style={{
              border: "none",
              borderBottom: "3px solid grey",
              borderRadius: "10px",
              backgroundColor: "transparent",
              width: "90%",
              margin: "auto",
              color: "white",
            }}
          />
          <FormHelperText className="text-danger" sx={{ fontSize: "13px" }}>
            {error?.usdt_address && error?.usdt_address[0]}
          </FormHelperText>
          <br />
          <h6 className="mt-3">Wallet Type</h6>
          <h5
            className="text-start px-2"
            style={{
              border: "none",
              borderBottom: "3px solid grey",
              borderRadius: "10px",
              backgroundColor: "transparent",
              width: "90%",
              margin: "auto",
              color: "white",
            }}
          >
            TRC20
          </h5>
          <br />
          <br />
          <button
            onClick={handleSubmit}
            className="mt-5 py-3 fw-bold"
            style={{
              width: "90%",
              borderRadius: "5px",
              backgroundColor: "#EA5B69",
              border: "none",
              color: "white",
            }}
          >
            Withdraw
          </button>
        </div>
        <div className="container mt-5">
          <ol className="text-start">
            <label>Withdrawal Instructions</label>
            <li>Please confirm whether the wallet address is correct.</li>
            <li>
              If the information is incorrectly filled in, we will not be
              responsible for your loss.
            </li>
            <li>
              The withdrawal amount will be processed within 2 to 72 hours.
            </li>
            <li>You can only apply for 1 withdrawals per day.</li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Withdraw;
